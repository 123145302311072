import { disableScroll, disableTouch, enableScroll, enableTouch, sendRequestWithData } from './helpers';
import { sendGenerateLead } from './business-events';
var nameInputId = 'name';
var emailInputId = 'contact-us-email';
var messageInputId = 'message';
var messageSentDialogElement = document.getElementById('message-sent-dialog');
var contactUsFormElement = document.getElementById('contact-us-form');
var nameInput = document.getElementById(nameInputId);
var emailInput = document.getElementById(emailInputId);
var messageInput = document.getElementById(messageInputId);
var closeMessageSentDialogElement = document.getElementById('close-message-sent-dialog');
var formSubmitButton = document.getElementById('submit-button');
var serverErrorElement = document.getElementById('server-error');
var countdown = document.getElementById('countdown');
var mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
var requiredErrorClass = 'required-error';
var mailFormatErrorClass = 'mail-format-error';
var maxSymbols = 250;
export function init() {
    if (!contactUsFormElement) {
        return;
    }
    if (countdown) {
        countdown.innerHTML = "0/".concat(maxSymbols);
    }
    contactUsFormElement.addEventListener('submit', onFormSubmitEvent);
    [nameInput, emailInput, messageInput].forEach(function (input) {
        input.addEventListener('keyup', onFormInputChanged);
        input.addEventListener('blur', onFormInputBlur);
    });
    closeMessageSentDialogElement.addEventListener('click', closeMessageSentDialog);
    window.addEventListener('click', onWindowClick);
}
function onFormSubmitEvent(event) {
    event.preventDefault();
    var formValid = validateForm();
    if (!formValid) {
        return;
    }
    var recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;
    var formData = Object.fromEntries(new FormData(contactUsFormElement).entries());
    var apiUrl = contactUsFormElement.getAttribute('action');
    if (formData.honeypot && formData.honeypot.length) {
        return;
    }
    hideServerError();
    formSubmitButton.disabled = true;
    grecaptcha.ready(function () {
        grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function (token) {
            formData.grecaptcha_token = token; // send this token to the handler endpoint for ReCAPTCHA score verification
            submitForm(formData, apiUrl);
        });
    });
}
function submitForm(formData, apiUrl) {
    sendRequestWithData(formData, apiUrl)
        .then(function () {
        contactUsFormElement.reset();
        openMessageSentDialog();
    })
        .catch(showServerError)
        .finally(function () {
        formSubmitButton.disabled = false;
    });
}
function openMessageSentDialog() {
    messageSentDialogElement.style.display = 'flex';
    sendGenerateLead();
    disableScroll();
    disableTouch();
}
function closeMessageSentDialog() {
    messageSentDialogElement.style.display = 'none';
    enableScroll();
    enableTouch();
}
function onWindowClick(event) {
    if (event.target === messageSentDialogElement) {
        closeMessageSentDialog();
    }
}
function showServerError(error) {
    serverErrorElement.style.display = 'grid';
    serverErrorElement.textContent = error;
}
function hideServerError() {
    serverErrorElement.style.display = 'none';
    serverErrorElement.textContent = '';
}
function onFormInputChanged(event) {
    var target = event.target;
    if (target.id === messageInputId) {
        countdown.innerHTML = "".concat(target.value.length, "/").concat(maxSymbols);
        target.parentElement.classList.remove(requiredErrorClass);
    }
    else {
        target.classList.remove(requiredErrorClass);
        target.classList.remove(mailFormatErrorClass);
    }
}
function onFormInputBlur(event) {
    var target = event.target;
    switch (target.id) {
        case nameInputId:
            validateName(target);
            break;
        case emailInputId:
            validateEmail(target);
            break;
        case messageInputId:
            validateMessage(target);
            break;
        default:
            break;
    }
}
function validateForm() {
    return [validateName(nameInput), validateEmail(emailInput), validateMessage(messageInput)].every(Boolean);
}
function validateName(element) {
    element.classList.remove(requiredErrorClass);
    var hasRequiredError = isElementFailedRequiredCheck(element);
    if (hasRequiredError) {
        element.classList.add(requiredErrorClass);
        return false;
    }
    return true;
}
function validateEmail(element) {
    element.classList.remove(requiredErrorClass);
    element.classList.remove(mailFormatErrorClass);
    var hasRequiredError = isElementFailedRequiredCheck(element);
    if (hasRequiredError) {
        element.classList.add(requiredErrorClass);
        return false;
    }
    var hasMailFormatError = isElementFailedMailFormatCheck(element);
    if (hasMailFormatError) {
        element.classList.add(mailFormatErrorClass);
        return false;
    }
    return true;
}
function validateMessage(element) {
    element.parentElement.classList.remove(requiredErrorClass);
    var hasRequiredError = isElementFailedRequiredCheck(element);
    if (hasRequiredError) {
        element.parentElement.classList.add(requiredErrorClass);
        return false;
    }
    return true;
}
function isElementFailedRequiredCheck(element) {
    return !element.value.trim().length;
}
function isElementFailedMailFormatCheck(element) {
    return !element.value.match(mailFormat);
}
