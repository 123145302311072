var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { enableBodyScroll, textEllipsis } from '../helpers';
import { generateInvitationLink } from './helpers';
import { getOSName } from '../parser';
var isShareSupported;
var copyButtonChangeStateTimerId;
var invitationLink;
var bannedOSForShare = ['windows'];
var copyButtonChangeStateDelay = 3000;
var invitationLinkMaxLength = 80;
var htmlElements = {};
export function init(getShortLinkUrl) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var osName, isOSBannedForShare;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setHTMLElements();
                    osName = (_a = getOSName()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
                    isOSBannedForShare = bannedOSForShare.includes(osName);
                    isShareSupported = !!navigator.share;
                    htmlElements.invitationLink.innerHTML = 'Loading...';
                    return [4 /*yield*/, generateInvitationLink(getShortLinkUrl)];
                case 1:
                    invitationLink = _b.sent();
                    htmlElements.invitationLink.innerHTML = textEllipsis(invitationLink, invitationLinkMaxLength);
                    htmlElements.closeButton.addEventListener('click', closeDialog);
                    htmlElements.copyLinkButton.addEventListener('click', copyLinkButtonElementClickListener);
                    if (isShareSupported && !isOSBannedForShare) {
                        htmlElements.shareLinkButton.removeAttribute('hidden');
                        htmlElements.shareLinkButton.addEventListener('click', shareLinkButtonElementClickListener);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function destroy() {
    htmlElements.closeButton.removeEventListener('click', closeDialog);
    htmlElements.copyLinkButton.removeEventListener('click', copyLinkButtonElementClickListener);
    if (isShareSupported) {
        htmlElements.shareLinkButton.setAttribute('hidden', '');
        htmlElements.shareLinkButton.removeEventListener('click', shareLinkButtonElementClickListener);
    }
}
function shareLinkButtonElementClickListener() {
    navigator
        .share({
        url: invitationLink,
    })
        .then(function () { return console.log('Share was successful.'); })
        .catch(function (error) { return console.log('Sharing failed', error); });
}
function copyLinkButtonElementClickListener() {
    var attributeName = 'aria-pressed';
    global.clearTimeout(copyButtonChangeStateTimerId);
    navigator.clipboard.writeText(invitationLink);
    htmlElements.copyLinkButton.setAttribute(attributeName, 'true');
    copyButtonChangeStateTimerId = global.setTimeout(function () {
        htmlElements.copyLinkButton.setAttribute(attributeName, 'false');
    }, copyButtonChangeStateDelay);
}
function setHTMLElements() {
    htmlElements.host = document.getElementById('invitation-dialog');
    htmlElements.closeButton = document.getElementById('invitation-dialog-close-button');
    htmlElements.copyLinkButton = document.getElementById('invitation-dialog-copy-link-button');
    htmlElements.shareLinkButton = document.getElementById('invitation-dialog-share-link-button');
    htmlElements.invitationLink = document.getElementById('invitation-dialog-invitation-link');
}
function closeDialog() {
    htmlElements.host.style.display = 'none';
    enableBodyScroll();
    destroy();
}
