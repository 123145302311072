var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getDesktopMinWidth } from './layout';
// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        },
    }));
}
catch (e) { }
var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
export function scrollToElement(element, offset, behavior) {
    if (!element) {
        return;
    }
    var bodyRect = document.body.getBoundingClientRect().top;
    var elementRect = element.getBoundingClientRect().top;
    var elementPosition = elementRect - bodyRect;
    var offsetPosition = elementPosition - offset;
    window.scrollTo({
        top: offsetPosition,
        behavior: behavior || 'smooth',
    });
}
export function scrollToElementCenter(element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}
export function getForLearnersPagePath() {
    return getPageUrlForSelectedLanguage('/');
}
export function getForTutorsPagePath() {
    return getPageUrlForSelectedLanguage('/become-a-student-tutor/');
}
export function getForParentsPagePath() {
    return getPageUrlForSelectedLanguage('/for-parents-and-guardians/');
}
export function isForTutorsPage() {
    return window.location.pathname === getForTutorsPagePath();
}
export function isForParentsPage() {
    return window.location.pathname === getForParentsPagePath();
}
export function isCollegeLearnersPage() {
    var bodyClasses = Array.from(document.body.classList);
    return bodyClasses.includes('college-learners-page');
}
export function isBecomeCollegeTutorPage() {
    var bodyClasses = Array.from(document.body.classList);
    return bodyClasses.includes('become-college-tutor-page');
}
export function isMainPage() {
    var bodyClasses = Array.from(document.body.classList);
    return bodyClasses.includes('main-page');
}
export function isBlogPages() {
    var bodyClasses = Array.from(document.body.classList);
    return bodyClasses.includes('is-blog');
}
export function isBlogPost() {
    return Array.from(document.body.classList).includes('is-post');
}
export function isInfoPages() {
    var bodyClasses = Array.from(document.body.classList);
    return ['site-policy', 'privacy-policy', 'terms-of-use', 'cookies-policy'].some(function (url) {
        return bodyClasses.includes(url);
    });
}
export function isParentPage() {
    var bodyClasses = Array.from(document.body.classList);
    return bodyClasses.includes('parents-and-guardians');
}
export function getPageUrlForSelectedLanguage(pageUrl) {
    var selectedLanguage = getSelectedLanguage();
    if (selectedLanguage === 'en') {
        return pageUrl;
    }
    return '/' + selectedLanguage + pageUrl;
}
export function getSelectedLanguage() {
    return detectLocalizationPrefix(document.location.href) || 'en';
}
export function detectLocalizationPrefix(url) {
    if (url === void 0) { url = ''; }
    var result = /\/([\w]{2})\//g.exec(url);
    return result ? result[1] : null;
}
export function goToForLearnersPage() {
    window.location.pathname = getForLearnersPagePath();
}
export function goToForTutorsPage() {
    window.location.pathname = getForTutorsPagePath();
}
export function goToForParentsPage() {
    window.location.pathname = getForParentsPagePath();
}
export function getWindowWidth() {
    return window.innerWidth;
}
export function isDesktop() {
    return getDesktopMinWidth() <= getWindowWidth();
}
export function disableBodyScroll() {
    document.body.style.overflow = 'hidden';
}
export function enableBodyScroll() {
    document.body.style.overflow = null;
}
export function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false);
    window.addEventListener(wheelEvent, preventDefault, wheelOpt);
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}
export function disableTouch() {
    window.addEventListener('touchmove', preventDefault, wheelOpt);
}
export function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
export function enableTouch() {
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
}
export function getQueryParams() {
    var urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
}
export function textEllipsis(str, maxLength, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.side, side = _c === void 0 ? 'end' : _c, _d = _b.ellipsis, ellipsis = _d === void 0 ? '...' : _d;
    if (str.length > maxLength) {
        switch (side) {
            case 'start':
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case 'end':
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
}
function preventDefault(e) {
    e.preventDefault();
}
function preventDefaultForScrollKeys(e) {
    // left: 37, up: 38, right: 39, down: 40,
    var keys = [37, 38, 39, 40];
    var keyCode = e.keyCode;
    if (keys.includes(keyCode)) {
        preventDefault(e);
        return false;
    }
}
export function sendRequestWithData(data, url) {
    var options = {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetch(url, options).then(function (response) {
        if (response.ok) {
            return response.json();
        }
        else {
            throw new Error('Something went wrong. Try again.');
        }
    });
}
export function sleep(ms) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, ms); })];
        });
    });
}
export function waitForElement(selector, container) {
    if (container === void 0) { container = document.body; }
    return __awaiter(this, void 0, void 0, function () {
        var element;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    element = container.querySelector(selector);
                    if (element) {
                        return [2 /*return*/, element];
                    }
                    return [4 /*yield*/, sleep(100)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, waitForElement(selector, container)];
            }
        });
    });
}
