var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Storage } from '../storage';
import { invitationParamsStorageKey } from './constants';
import { sendRequestWithData } from '../helpers';
// TODO: use other solution for switching between short/long url
var useShortUrl = true;
export function generateInvitationLink(getShortLinkUrl) {
    return __awaiter(this, void 0, void 0, function () {
        var invitationParamsFromStorage, invitationParams, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    invitationParamsFromStorage = getInvitationParamsFromStorage();
                    if (!invitationParamsFromStorage) {
                        return [2 /*return*/, null];
                    }
                    invitationParams = JSON.parse(invitationParamsFromStorage);
                    if (!useShortUrl) {
                        return [2 /*return*/, generateFullInvitationLink(invitationParams)];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, generateShortInvitationLink(getShortLinkUrl, invitationParams)];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    e_1 = _a.sent();
                    return [2 /*return*/, generateFullInvitationLink(invitationParams)];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function generateFullInvitationLink(_a) {
    var parent_utm_source = _a.parent_utm_source, parent_utm_campaign = _a.parent_utm_campaign, parent_client_id = _a.parent_client_id;
    var appSignUpUrl = new URL("".concat(process.env.APP_LINK, "/auth/register"));
    appSignUpUrl.searchParams.append('type', 'parent_invitation');
    appSignUpUrl.searchParams.append('utm_source', parent_utm_source);
    appSignUpUrl.searchParams.append('utm_medium', 'parent-invitation');
    appSignUpUrl.searchParams.append('utm_campaign', parent_utm_campaign);
    if (!!parent_client_id) {
        appSignUpUrl.searchParams.append('parent_id', parent_client_id.toString());
    }
    return appSignUpUrl.href;
}
function generateShortInvitationLink(getShortLinkUrl, _a) {
    var parent_utm_source = _a.parent_utm_source, parent_utm_campaign = _a.parent_utm_campaign, parent_client_id = _a.parent_client_id;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            data = {
                type: 'parent_invitation',
                params: {
                    utm_medium: 'parent-invitation',
                    utm_source: parent_utm_source,
                    utm_campaign: parent_utm_campaign,
                    parent_id: parent_client_id ? parent_client_id.toString() : undefined,
                },
            };
            return [2 /*return*/, sendRequestWithData(data, getShortLinkUrl).then(function (response) { return response.data.short_link; })];
        });
    });
}
export function setInvitationParamsToStorage(value) {
    Storage.setItem(invitationParamsStorageKey, JSON.stringify(value));
}
export function getInvitationParamsFromStorage() {
    return Storage.getItem(invitationParamsStorageKey);
}
export function getReferrerHostname(referrer) {
    var _a;
    if (referrer === void 0) { referrer = document.referrer; }
    var referrerHostname;
    try {
        referrerHostname = (_a = new URL(referrer)) === null || _a === void 0 ? void 0 : _a.hostname;
    }
    catch (err) {
        referrerHostname = '';
    }
    return referrerHostname;
}
